<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{ `${$t("actions.edit")} ${$t("luna.face.title")}` }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <div class="m-2">
        <b-avatar :src="itemData.media ? itemData.media.url : ''" size="10rem"></b-avatar>

        <!-- Master Account -->
        <validation-provider
          #default="validationContext"
          name="master account"
          rules="required"
        >
          <b-form-group
            :label="$tc('luna.master_account.title', 1)"
            label-for="master account"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="masterAccount"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="lunaMasterAccountOptions"
              :reduce="(val) => val.value"
              input-id="master_account"
              :clearable="false"
              :disabled ="true"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver">

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          style="display: inline-flex;
                  width: 100%;"
        >
          <div
            style="width: -webkit-fill-available;
            padding: 0 1rem;"
          >
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="name face"
              rules="required"
            >
              <b-form-group
                class="text-capitalize"
                :label="$t('luna.face.name')"
                label-for="name face"
              >
                <b-form-input
                  id="name"
                  v-model="itemData.user_data"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- List -->
            <validation-provider
              v-if="!itemData.id"
              #default="validationContext"
              name="list"
              rules="required"
            >
              <b-form-group
                :label="$t('luna.list.title')"
                label-for="list"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="itemData.list"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="lunaListOptions"
                  :clearable="false"
                  :reduce="(val) => val.value"
                  input-id="list"
                  multiple

                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 text-capitalize"
                type="submit"
              >
                {{ $t("actions.save") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
                class="text-capitalize"
              >
                {{ $t("actions.cancel") }}
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    lunaListOptions: {
      type: Array,
      required: true,
    },
    lunaMasterAccountOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const listArray = ref([]);
    const masterAccount = ref();

    const { t } = useI18nUtils();
    const blankData = {
      id_luna_master_account: null,
      list: null,
      name: null,
      file: null,
      external_id: null,
      attributes: null,
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;

      let req = {
        id: itemData.value.id,
        params: itemData.value
      };

      dispatch = store.dispatch("luna_face/edit", req);

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      itemData.value = props.itemEdit;
      masterAccount.value = itemData.value.id_luna_master_account;
      listArray.value = props.itemEdit.lists.map((list) => list.id);
    };

    return {
      itemData,
      onSubmit,
      listArray,
      refFormObserver,
      getValidationState,
      loadData,
      masterAccount,

      resetForm,
    };
  },
};
</script>