<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg full-width-custom"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetData"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{ `${$t("luna.face.similarity_match")}` }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <div class="m-2">
        <!-- Master Account -->
        <validation-provider
          #default="validationContext"
          name="master account"
          rules="required"
          v-show="!masterAccount"
        >
          <b-form-group
            :label="$tc('luna.master_account.title', 1)"
            label-for="master account"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="masterAccount"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="lunaMasterAccountOptions"
              :reduce="(val) => val.value"
              input-id="master_account"
              :clearable="false"
              :disabled="itemData.id"
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-form-group label="Image" label-for="dropzone">
          <vue-dropzone
            v-show="!imageUrl"
            id="dropzone"
            ref="dropzoneImage"
            :options="dropzoneOptions"
            class="dropzone-main"
            @vdropzone-file-added="addFileImage"
            @vdropzone-removed-file="removeFileImage"
            @vdropzone-sending="sendingFileImage"
            @vdropzone-success="success"
            @vdropzone-error="error"
          />

          <div class="image-container">
            <b-img v-show="imageUrl" :src="imageUrl" style="width: 100%" />
          </div>
        </b-form-group>
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
        v-for="face in faces"
        :key="face.sample_id"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit()"
          style="display: inline-flex; width: 100%"
        >
          <b-img
            :src="face.url_image"
            style="width: 10rem; height: fit-content; margin: auto"
          />
          <div style="width: -webkit-fill-available; padding: 0 1rem">
            <!-- List -->
            <validation-provider
              v-if="!itemData.id"
              #default="validationContext"
              name="list"
              rules="required"
            >
              <b-form-group
                :label="$t('luna.list.title')"
                label-for="list"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="lunaList"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="lunaListOptions"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
          </div>
        </b-form>
        <div
          class="d-flex justify-content-between align-items-center px-2 py-1"
          v-if="facesMatch"
        >
          <div v-for="face in facesMatch" :key="face.id">
            <table class="table table-sm table-striped">
              <tr>
                <th>
                  <b-img
                    :src="`https://luna.omniview.cloud:9000${face.face.avatar}`"
                    style="width: 10rem; height: fit-content; margin: auto"
                  />
                </th>
                <td>{{ face.face.user_data }}</td>
                <td>{{ (face.similarity * 100).toFixed(2) }} %</td>
              </tr>
            </table>
          </div>
        </div>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { watch } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref, computed } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import axios from "@axios";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    vueDropzone: vue2Dropzone,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    lunaListOptions: {
      type: Array,
      required: true,
    },
    lunaMasterAccountOptions: {
      type: Array,
      required: true,
    },
    imageUrl: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const listArray = ref([]);
    const masterAccount = ref();

    const { t } = useI18nUtils();
    const blankData = {
      id_luna_master_account: null,
      list: null,
      name: null,
      file: null,
      external_id: null,
      attributes: null,
    };

    const dropzoneOptions = ref({
      url: `${process.env.VUE_APP_BASE_URL}/v1/services/facial-recognition/luna/extract/luna-face`,
      paramName: "file",
      autoProcessQueue: false,
      dictDefaultMessage: "",
      thumbnailMethod: "crop",
      addRemoveLinks: true,
      thumbnailWidth: null,
      thumbnailHeight: null,
      dictRemoveFile: t("actions.delete"),
      acceptedFiles: "image/*",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      dictDefaultMessage:
        t("messages.import.select_file") + " (.png .jpeg .jpg)",
    });

    const sendingFileImage = (file, xhr, formData) => {
      formData.append("id_luna_master_account", masterAccount.value);
    };

    const dropzoneImage = ref(null);
    const dropzoneImageFile = ref(null);
    const hasImage = computed(() => !!itemData.value.image);
    const faces = ref([]);
    const luna_server = ref();

    const hasFaces = ref(false);

    const addFileImage = (file) => {
      dropzoneImageFile.value = file;

      if (masterAccount.value) {
        addFace();
        setTimeout(() => dropzoneImage.value.processQueue(), 500);
      }
    };

    const removeFileImage = () => {
      deleteFace();
      dropzoneImageFile.value = null;
      faces.value = [];
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      deleteFace();
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const { refFormObserver, getValidationState } = formValidation(resetData);

    const loadData = () => {
      listArray.value = [];
      masterAccount.value = props.lunaMasterAccountOptions[0].value;
      dropzoneImageFile.value = null;
      dropzoneImage.value.removeAllFiles();

      if (props.imageUrl) {
        let mockFile = {
          size: 1234,
          name: "snapshot.jpeg",
          type: `image/jpeg`,
          kind: "image",
          upload: {
            filename: "snapshot.jpeg",
          },
          dataURL: props.imageUrl,
        };

        dropzoneImage.value.manuallyAddFile(
          mockFile,
          props.imageUrl,
          null,
          null,
          {
            dontSubstractMaxFiles: false,
            addToFiles: true,
          }
        );
      }
    };

    let createCanvas = (class_name, translate = false) => {
      const htmlPlayer = document.querySelector(class_name);
      const canvasId = `canvas`;
      const oldCanvas = htmlPlayer.querySelector(`#${canvasId}`);

      if (oldCanvas) {
        return oldCanvas;
      }

      const canvas = document.createElement("canvas");
      canvas.id = canvasId;
      canvas.width = document.querySelector(`${class_name} img`).width;
      canvas.height = document.querySelector(`${class_name} img`).height;
      canvas.style.top = 0;
      canvas.style.left = 0;
      canvas.style.position = "absolute";
      canvas.style.zIndex = 100;
      if (translate) {
        canvas.style.left = "50%";
        canvas.style.transform = "translateX(-50%)";
      }
      htmlPlayer.appendChild(canvas);

      return canvas;
    };

    let drawLandmarks = (faces, class_name, translate) => {
      let img = document.querySelector(`${class_name} img`);
      let factor = img.height / img.naturalHeight;
      faces.forEach((face) => {
        const canvas = createCanvas(class_name, translate);
        const ctx = canvas.getContext("2d");
        const width = face.detection.rect.width * factor;
        const height = face.detection.rect.height * factor;
        const x = face.detection.rect.x * factor;
        const y = face.detection.rect.y * factor;
        ctx.strokeStyle = "lime";
        ctx.strokeRect(x, y, width, height);
      });
    };

    const success = (file, response) => {
      hasFaces.value = true;
      drawLandmarks(response.data.faces, ".dz-image", true);
      faces.value = response.data.faces;
      luna_server.value = response.data.master_account.api_server.replace(
        "/6/",
        ""
      );
    };

    const error = (file, message, xhr) => {
      toast({
        component: ToastificationContent,
        props: {
          title: message.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const removeElement = (sample_id) => {
      faces.value = faces.value.filter((value, index, arr) => {
        if (value.sample_id == sample_id) return false;

        return true;
      });
    };

    const lunaList = ref("");
    const facesMatch = ref([]);
    const lunaFace = ref([]);
    const lunaAddFace = ref(null);

    const addFace = () => {
      facesMatch.value = [];
      lunaList.value = '';
      let form_data = new FormData();
      let dispatch;

      form_data.append("id_luna_master_account", masterAccount.value);
      form_data.append("file", dropzoneImageFile.value);
      form_data.append("user_data", "similarity/face");

      dispatch = store.dispatch("luna_face/addFace", form_data);

      dispatch
        .then((response) => {
          if (response) {
            lunaAddFace.value = response[0];
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    watch(lunaList, (id) => {
      if(id){
        lunaFace.value = [];
        getFaceList(id);
      }
    });

    const getFaceList = (id) => {
      axios
        .get("v1/services/facial-recognition/luna/luna-face?list[]=" + id)
        .then((response) => {
          if (response.data.success) {
            if (response.data.data.length > 0) {
              response.data.data.forEach((face) => {
                lunaFace.value.push(face.face_id);
              });

              if (lunaFace.value.length > 0) {
                let params = {
                  id_luna_master_account: masterAccount.value,
                  face_id: lunaAddFace.value.face_id,
                  face_ids: lunaFace.value,
                };

                getFaceSimilarity(params);
              }
            } else {
              facesMatch.value = [];
              lunaFace.value = [];
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const getFaceSimilarity = (params) => {
      facesMatch.value = [];
      axios
        .post(
          "v1/services/facial-recognition/luna/luna-face/get-similarity",
          params
        )
        .then((response) => {
          if (response.data.length > 0) {
            facesMatch.value = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    const deleteFace = () => {
      if (masterAccount.value && lunaAddFace.value) {
        let params = {
          id_luna_master_account: masterAccount.value,
          face_id: lunaAddFace.value.face_id,
        };
        store
          .dispatch("luna_face/deleteFace", params)
          .then((response) => {
            lunaAddFace.value = null;
            console.log(response);
          })
          .catch((response) => {
            console.log(response);
          });
      }
    };

    return {
      itemData,
      //onSubmit,
      listArray,
      refFormObserver,
      getValidationState,
      loadData,
      masterAccount,

      dropzoneOptions,
      dropzoneImage,
      addFileImage,
      removeFileImage,
      hasImage,

      hasFaces,
      sendingFileImage,
      success,
      error,
      resetData,

      faces,
      luna_server,

      removeElement,
      lunaAddFace,
      lunaList,
      facesMatch,
      lunaFace,
    };
  },
};
</script>

<style lang="scss">
.dz-preview {
  margin: 0 !important;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.dropzone .dz-preview .dz-image img {
  width: 100% !important;
  height: auto !important;
  margin: auto;
}

.dropzone-main {
  width: 100%;
  height: auto;
  min-height: 5vw;
  margin: auto;
  padding: 0;
  background-position: center;
  background-size: cover;
}

.dz-remove {
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}

.vue-dropzone > .dz-preview:hover .dz-remove {
  margin: 0;
}

.dropzone .dz-preview:hover .dz-image img {
  filter: none !important;
  transform: none !important;
}

.dz-size {
  text-align: center;
}

.dz-message {
  margin: 0 !important;
}

.full-width-custom {
  min-width: 60vw;
}

.image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.b-sidebar-body::-webkit-scrollbar {
  display: none;
}
</style>
