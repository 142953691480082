<template>
  <div>
    <toastification-loading-content ref="loadingToast"/>
    <toastification-progress-content ref="progressToast"/>

    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      @refetch-data="refetchData"
      :luna-master-account-options="lunaMasterAccountOptions"
      :luna-list-options="lunaListOptions"
    />

    <edit-sidenav
      :is-sidebar-active.sync="isSidebarActiveEdit"
      :item-edit.sync="itemEdit"
      @refetch-data="refetchData"
      :luna-master-account-options="lunaMasterAccountOptions"
      :luna-list-options="lunaListOptions"
    />

    <similarity-sidenav
      :is-sidebar-active.sync="isSidebarActiveSimilarity"
      @refetch-data="refetchData"
      :luna-master-account-options="lunaMasterAccountOptions"
      :luna-list-options="lunaListOptions"
    />

    <Breadcrumb
      :nameNav="nameNav"
      :itemFilter="itemFilter"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />

    <filters
      :luna-master-account-filter.sync="lunaMasterAccountFilter"
      :luna-master-account-options="lunaMasterAccountOptions"
      :luna-list-filter.sync="lunaListFilter"
      :luna-list-options="lunaListOptions"
      :gender-filter.sync="genderFilter"
      :gender-options="genderOptions"
      :age-filter.sync="ageFilter"
      :age-options="ageOptions"
      @update-face-data="updateFace"
      :button-update="buttonUpdate"
    />

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />
              <b-button
                variant="primary"
                @click="openAdd()"
                v-if="$can('create', 'LunaFace')"
              >
                <span class="text-nowrap text-capitalize">
                  {{ `${$t("actions.add")} ${$t("luna.face.title")}` }}
                </span>
              </b-button>
              <b-button
                class="ml-1"
                variant="info"
                @click="similaritySidenav()"
                v-if="$can('create', 'LunaFace')"
                v-b-tooltip.hover
                :title="`${$t('luna.face.similarity_match')}`"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-person-bounding-box" viewBox="0 0 16 16">
                  <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5M.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5"/>
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm8-9a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                </svg>
              </b-button>
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize ml-1"
                type="button"
                :disabled="buttonExport"
                v-b-tooltip.hover
                :title="`${$t('messages.export.title')}`"
              >
                <feather-icon icon="DownloadCloudIcon" size="15"/>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #cell(detection)="data">
          <pre style="height: 10rem; width: 25rem; overflow: auto;">
            {{  JSON.parse(data.item.detection) }}
          </pre>
        </template>

        <template #cell(image)="data">
          <div class="hoverable">
            <b-img
              :src="data.item.avatar.includes('samples') 
                ? `https://luna.omniview.cloud:9000${data.item.avatar}` 
                : `https://luna.omniview.cloud:9000/6/samples/${data.item.avatar}`"
              alt="path Image"
              width="85"
              height="100"
            ></b-img>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="openEdit(data.item)"
              v-if="$can('update', 'LunaFace')"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50 text-capitalize">{{
                $t("actions.edit")
              }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="confirmDelete(data.item.id)"
              v-if="$can('delete', 'LunaFace')"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50 text-capitalize">{{
                $t("actions.delete")
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, computed, watch } from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import EditSidenav from "./EditSidenav.vue";
import Filters from "./Filters.vue";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import ToastificationContent
  from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent
  from "@core/components/toastification/ToastificationLoadingContent.vue";
import ToastificationProgressContent
  from "@core/components/toastification/ToastificationProgressContent.vue";
import SimilaritySidenav from './SimilaritySidenav.vue';

export default {
  components: {
    Sidenav,
    EditSidenav,
    Filters,
    Breadcrumb,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ToastificationLoadingContent,
    ToastificationProgressContent,
    Pagination,
    SimilaritySidenav,
  },

  setup(_, context) {
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    // start breadcrumb
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("luna.face.title");
    const notifications = computed(() => store.getters["grid/getAlarms"]);
    // end breadcrumb

    const buttonExport = ref(false);
    const loadingToast = ref(null);
    const progressToast = ref(null);
    const progress = computed(() => store.getters["luna_face/getProgress"]);
    const error = computed(() => store.getters["luna_face/getError"]);
    const url = computed(() => store.getters["luna_face/getUrl"]);

    store.dispatch("grid/findDownloadNotification", "luna-face").then((data) => {
      if (data.length > 0) {
        buttonExport.value = true;
        progressToast.value.show("Descarga", "Generando archivo...", "secondary");
      }
    });

    const exportExcel = () => {
      buttonExport.value = true;
      store.dispatch("luna_face/resetProgress");
      progressToast.value.show("Descarga", "Generando archivo...", "secondary");
      store
        .dispatch("luna_face/getAll", {
          q: searchQuery.value,
          max: perPage.value,
          "page[n]": currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          action: "export",
          luna_master_account: lunaMasterAccountFilter.value,
          list: lunaListFilter.value,
          gender: genderFilter.value,
          age: ageFilter.value
        })
        .then((response) => {
          store.dispatch(
            "luna_face/setExportLunaFace",
            response.data.jobId
          );
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error exportando la información",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          buttonExport.value = false;
          progressToast.value.close();
        });
    };

    watch([progress], () => {
      progressToast.value.updateProgress(progress.value);
    });

    watch([error], () => {
      toast({
        component: ToastificationContent,
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
      buttonExport.value = false;
      progressToast.value.close();
    });

    watch([url], () => {
      if (url.value) {
        const link = document.createElement("a");
        link.href = url.value;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        buttonExport.value = false;
        progressToast.value.close();
      }
    });

    const formatPercentages = (num) => {
      return `${(parseFloat(num) * 100).toFixed(2)} %`;
    };


    const isSidebarActive = ref(false);
    const isSidebarActiveEdit = ref(false);
    const itemEdit = ref({});
    const openEdit = (item) => {
      isSidebarActiveEdit.value = true;
      itemEdit.value = item;
    };
    const { t } = useI18nUtils();
    const toast = useToast();
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      lunaMasterAccountOptions,
      lunaMasterAccountFilter,
      lunaListOptions,
      lunaListFilter,
      genderOptions,
      genderFilter,
      ageOptions,
      ageFilter,
    } = useList();

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: context.parent.$i18n.t("messages.delete.title"),
        text: context.parent.$i18n.t("messages.delete.body"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t("actions.delete"),
        cancelButtonText: context.parent.$i18n.t("actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("luna_face/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    const openAdd = () => {
      isSidebarActive.value = true;
    };

    const buttonUpdate = ref(false)
    const updateFace = () => {
      buttonUpdate.value = true;
      loadingToast.value.show("Actualizando", `Actualizando caras ...`, "secondary");
      store
      .dispatch("luna_face/syncFace")
      .then((response) => {
        if (response.success) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          refetchData();
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      })
      .catch((response) => {
        toast({
          component: ToastificationContent,
          props: {
            title: response.response.data.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        buttonUpdate.value = false;
        loadingToast.value.close();
      })
    };

    const isSidebarActiveSimilarity = ref(false);
    const similaritySidenav = () => {
      isSidebarActiveSimilarity.value = true;
    }

    return {
      isSidebarActive,
      isSidebarActiveEdit,
      itemEdit,
      openEdit,
      openAdd,
      confirmDelete,
      itemFilter,
      addItemFilter,
      addItemFilterSearch,
      nameNav,
      notifications,
      buttonExport,
      loadingToast,
      exportExcel,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatPercentages,
      formatDateAssigned,
      lunaMasterAccountOptions,
      lunaMasterAccountFilter,
      lunaListOptions,
      lunaListFilter,
      genderOptions,
      genderFilter,
      ageOptions,
      ageFilter,
      update,
      progress,
      progressToast,
      updateFace,
      buttonUpdate,
      similaritySidenav,
      isSidebarActiveSimilarity,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
