<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg full-width-custom"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetData"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{ `${$t("actions.add")} ${$tc("luna.face.title", 2)}` }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <div class="m-2">
        <!-- Master Account -->
        <validation-provider
          #default="validationContext"
          name="master account"
          rules="required"
          v-show="!masterAccount"
        >
          <b-form-group
            :label="$tc('luna.master_account.title', 1)"
            label-for="master account"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="masterAccount"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="lunaMasterAccountOptions"
              :reduce="(val) => val.value"
              input-id="master_account"
              :clearable="false"
              :disabled = itemData.id
            />
            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-form-group
            label="Image"
            label-for="dropzone"
          >
          <vue-dropzone
            v-show="!imageUrl"
            id="dropzone"
            ref="dropzoneImage"
            :options="dropzoneOptions"
            class="dropzone-main"
            @vdropzone-file-added="addFileImage"
            @vdropzone-removed-file="removeFileImage"
            @vdropzone-sending="sendingFileImage"
            @vdropzone-success="success"
            @vdropzone-error="error"
          />

          <div class="image-container">
            <b-img v-show="imageUrl" :src="imageUrl" style="width: 100%;" />
          </div>
        </b-form-group>
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
        v-for="face in faces"
        :key="face.sample_id">

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(() => onSubmit(face))"
          style="display: inline-flex;
                  width: 100%;"
        >
          <b-img :src="face.url_image"
            style="width: 10rem;
            height: fit-content;
            margin: auto;"
          />

          <div
            style="width: -webkit-fill-available;
            padding: 0 1rem;"
          >
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="name face"
              rules="required"
            >
              <b-form-group
                class="text-capitalize"
                :label="$t('luna.face.name')"
                label-for="name face"
              >
                <b-form-input
                  id="name"
                  v-model="face.user_data"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- List -->
            <validation-provider
              v-if="!itemData.id"
              #default="validationContext"
              name="list"
              rules="required"
            >
              <b-form-group
                :label="$t('luna.list.title')"
                label-for="list"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="face.list"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="lunaListOptions"
                  :clearable="false"
                  :reduce="(val) => val.value"
                  input-id="list"
                  multiple

                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 text-capitalize"
                type="submit"
              >
                {{ $t("actions.save") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="removeElement(face.sample_id)"
                class="text-capitalize"
              >
                {{ $t("actions.cancel") }}
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { watch } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref, computed } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    vueDropzone: vue2Dropzone,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    lunaListOptions: {
      type: Array,
      required: true,
    },
    lunaMasterAccountOptions: {
      type: Array,
      required: true,
    },
    imageUrl: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const listArray = ref([]);
    const masterAccount = ref();

    const { t } = useI18nUtils();
    const blankData = {
      id_luna_master_account: null,
      list: null,
      name: null,
      file: null,
      external_id: null,
      attributes: null,
    };

    const dropzoneOptions = ref({
      url: `${process.env.VUE_APP_BASE_URL}/v1/services/facial-recognition/luna/extract/luna-face`,
      paramName: "file",
      autoProcessQueue: false,
      dictDefaultMessage: "",
      thumbnailMethod: 'crop',
      addRemoveLinks: true,
      thumbnailWidth: null,
      thumbnailHeight: null,
      dictRemoveFile: t("actions.delete"),
      acceptedFiles: "image/*",
      headers: { "Authorization": `Bearer ${localStorage.getItem('accessToken')}` },
      dictDefaultMessage: t('messages.import.select_file') + ' (.png .jpeg .jpg)',
    });

    const sendingFileImage = (file, xhr, formData) => {
      formData.append('id_luna_master_account', masterAccount.value);
    }

    const dropzoneImage = ref(null);
    const dropzoneImageFile = ref(null);
    const hasImage = computed(() => !!itemData.value.image);
    const faces = ref([])
    const luna_server = ref()

    const hasFaces = ref(false);

    const addFileImage = (file) => {
      dropzoneImageFile.value = file;

      if (masterAccount.value) {
        setTimeout(() => dropzoneImage.value.processQueue(), 500);
      }
    };

    const removeFileImage = () => {
      dropzoneImageFile.value = null;
      faces.value = [];
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = (face) => {
      let dispatch;

      let form_data = new FormData();

      for ( var key in face) {
        if (key == 'list') continue;
        if(face[key] instanceof Object)
          form_data.append(key, JSON.stringify(face[key]));
        else
          form_data.append(key, face[key]);
      }

      face.list.forEach(list => {
        form_data.append('list[]', list)
      })

      form_data.append('id_luna_master_account', masterAccount.value);
      form_data.append('image', dropzoneImageFile.value);

      dispatch = store.dispatch("luna_face/add", form_data);

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            removeElement(face.sample_id);
            emit("refetch-data");
            if (faces.value.length == 0) emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState } =
      formValidation(resetData);

    const loadData = () => {
      listArray.value = [];
      masterAccount.value = props.lunaMasterAccountOptions[0].value;
      dropzoneImageFile.value = null;
      dropzoneImage.value.removeAllFiles();

      if (props.imageUrl) {
        let mockFile = {
          size: 1234,
          name: 'snapshot.jpeg',
          type: `image/jpeg`,
          kind: 'image',
          upload: {
            filename: 'snapshot.jpeg',
          },
          dataURL: props.imageUrl,
        };

        dropzoneImage.value.manuallyAddFile(
            mockFile,
            props.imageUrl,
            null,
            null,
            {
              dontSubstractMaxFiles: false,
              addToFiles: true
            }
        );
      }
    };

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    }

    let submitFile = (image) => {
      let dispatch;

      let form_data = new FormData();

      form_data.append('file', image)
      form_data.append('id_luna_master_account', masterAccount.value);

      dispatch = store.dispatch("luna_face/extract", form_data);

      dispatch
        .then((response) => {
          if (response.success) {
            hasFaces.value = true;
            drawLandmarks(response.data.faces, ".image-container", false);
            faces.value = response.data.faces;
            luna_server.value = response.data.master_account.api_server.replace("/6/", "");
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    }

    watch(masterAccount, id => {
      if (masterAccount.value && dropzoneImageFile.value) {
        if(props.imageUrl) {
          submitFile(dataURLtoFile(props.imageUrl))
        }
        else {
          setTimeout(() => dropzoneImage.value.processQueue(), 500);
        }
      }
      store
        .dispatch("luna_list/getAll", {
          luna_master_account : id
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error obteniendo la información",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    });

    let createCanvas = (class_name, translate = false) => {
      const htmlPlayer = document.querySelector(class_name);
      const canvasId = `canvas`;
      const oldCanvas = htmlPlayer.querySelector(`#${canvasId}`);

      if (oldCanvas) {
        return oldCanvas;
      }

      const canvas = document.createElement("canvas");
      canvas.id = canvasId;
      canvas.width = document.querySelector(`${class_name} img`).width;
      canvas.height = document.querySelector(`${class_name} img`).height;
      canvas.style.top = 0;
      canvas.style.left = 0;
      canvas.style.position = "absolute";
      canvas.style.zIndex = 100;
      if (translate) {
        canvas.style.left = "50%";
        canvas.style.transform = "translateX(-50%)";
      }
      htmlPlayer.appendChild(canvas);

      return canvas;
    }

    let drawLandmarks = (faces, class_name, translate) => {
      let img = document.querySelector(`${class_name} img`);
      let factor = img.height / img.naturalHeight;
      faces.forEach((face) => {
        const canvas = createCanvas(class_name, translate);
        const ctx = canvas.getContext("2d");
        const width = face.detection.rect.width * factor;
        const height = face.detection.rect.height * factor;
        const x =  face.detection.rect.x * factor;
        const y = face.detection.rect.y * factor;
        ctx.strokeStyle = "lime";
        ctx.strokeRect(x, y, width, height);
      });
    };

    const success = (file, response) => {
      hasFaces.value = true;
      drawLandmarks(response.data.faces, ".dz-image", true);
      faces.value = response.data.faces;
      luna_server.value = response.data.master_account.api_server.replace("/6/", "");
    }

    const error = (file, message, xhr) => {
      toast({
            component: ToastificationContent,
            props: {
              title: message.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
    }

    const removeElement = (sample_id) => {
      faces.value = faces.value.filter((value, index, arr) => {
        if (value.sample_id == sample_id) return false;

        return true;
      });
    }

    return {
      itemData,
      onSubmit,
      listArray,
      refFormObserver,
      getValidationState,
      loadData,
      masterAccount,

      dropzoneOptions,
      dropzoneImage,
      addFileImage,
      removeFileImage,
      hasImage,

      hasFaces,
      sendingFileImage,
      success,
      error,
      resetData,

      faces,
      luna_server,

      removeElement,
    };
  },
};
</script>

<style lang="scss">
.dz-preview {
  margin: 0 !important;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.dropzone .dz-preview .dz-image img {
  width: 100% !important;
  height: auto !important;
  margin: auto;
}

.dropzone-main {
  width: 100%;
  height: auto;
  min-height: 5vw;
  margin: auto;
  padding: 0;
  background-position: center;
  background-size: cover;
}

.dz-remove {
  margin: 0;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
}

.vue-dropzone > .dz-preview:hover .dz-remove {
  margin: 0;
}

.dropzone .dz-preview:hover .dz-image img {
  filter: none !important;
  transform: none !important;
}

.dz-size {
  text-align: center;
}

.dz-message {
  margin: 0 !important;
}

.full-width-custom {
  min-width: 60vw;
}

.image-container{
  position: relative;
  width: 100%;
  height: auto;
}

.b-sidebar-body::-webkit-scrollbar {
  display: none;
}

</style>
